// @packages
import "prismjs";
import "prismjs/components/prism-jsx.min";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";

// @scripts
import "@components/atoms/ripple-button";
import Spinner from "@components/atoms/spinner/Fallback-spinner";
import { ThemeContext } from "./utility/context/ThemeColors";
import { store } from "./redux/storeConfig/store";

// @styles
import "bootstrap/dist/css/bootstrap.min.css";
import "prismjs/themes/prism-tomorrow.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/_variables.scss";
import "@styles/global.scss";

import * as serviceWorker from "./serviceWorker";
const LazyApp = lazy(() => import("./App"));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
